import React, { useState } from "react"
import { Link } from "gatsby"

import classes from "./ContactForm.module.scss"
import CheckIcon from "@material-ui/icons/Check"

const ContactForm = () => {
  const [fullname, setFullname] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [isChecked, setIsChecked] = useState(false)
  return (
    <form
      className={classes.form}
      method="post"
      action="https://www.flexyform.com/f/e85c17d8f9740640666dfe29151d659495f962dc"
    >
      <input
        type="text"
        name="fullname"
        placeholder="Imię i nazwisko"
        value={fullname}
        onChange={event => setFullname(event.target.value)}
        required
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={email}
        onChange={event => setEmail(event.target.value)}
        required
      />
      <textarea
        name="message"
        wrap="hard"
        placeholder="Wiadomość"
        value={message}
        onChange={event => setMessage(event.target.value)}
        required
      ></textarea>
      <label className={classes.policy} for="check">
        <span className={classes.checkbox__input}>
          <input
            className={classes.checkbox}
            name="check"
            type="checkbox"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            required
          />
          <span className={classes.checkbox__control}>
            <CheckIcon />
          </span>
        </span>
        <span className={classes.label}>
          Zapoznałem się z{" "}
          <Link to="/polityka-prywatnosci/">Polityką Prywatności </Link>
        </span>
      </label>
      <button type="submit" className={classes.submit_btn}>
        Wyślij
      </button>
    </form>
  )
}

export default ContactForm

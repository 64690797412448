import React, { useEffect, useRef } from "react"

import Img from "gatsby-image"

import classes from "./Contact.module.scss"
import ContactForm from "./ContactForm/ContactForm"
import ContactData from "./ContactData/ContactData"

const Contact = ({ title_photo }) => {
  const ref = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: "smooth" })
    }, 3000)
  })

  return (
    <div className={classes.contact}>
      <section id={classes.title}>
        <div className={classes.text}>
          <h1>Masz jakieś pytania?</h1>
        </div>
        <Img
          className={classes.photo}
          fluid={title_photo.childImageSharp.fluid}
          alt={"kontakt"}
        />
      </section>
      <section id={classes.contact_data} ref={ref}>
        <ContactData />
      </section>
      <section id={classes.contactMe}>
        <h2 className={classes.form_title}>Skontaktuj się ze mną!</h2>
        <ContactForm />
      </section>
    </div>
  )
}

export default Contact

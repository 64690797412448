import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import classes from "./ContactData.module.scss"
import EmailIcon from "@material-ui/icons/Email"
import PhoneIcon from "@material-ui/icons/Phone"
import RoomIcon from "@material-ui/icons/Room"
import GoogleMap from "../GoogleMap/GoogleMap"

const ContactData = () => {
  const data = useStaticQuery(query)
  const contactData = data.strapiKontakt
  const hoursData = data.strapiGodzinyOtwarcia
  return (
    <div className={classes.data}>
      <div className={classes.first_row}>
        <div className={classes.data_item}>
          <PhoneIcon />
          <a href={`tel:${contactData.telefon}`}>
            <h4 className={classes.phone}>{contactData.telefon}</h4>
          </a>
        </div>
        <div className={classes.data_item}>
          <EmailIcon />
          <a href={`mailto:${contactData.email}`}>
            <h4 className={classes.email}>{contactData.email}</h4>
          </a>
        </div>
        <div className={classes.data_item}>
          <RoomIcon />
          <div className={classes.address}>
            <h4>{contactData.miasto}</h4>
            <h4>{contactData.ulica}</h4>
            <h4>{contactData.adres_dodatkowe_info}</h4>
          </div>
        </div>
      </div>
      <div className={classes.second_row}>
        <div className={classes.opening_hours}>
          <h4>Godziny otwarcia</h4>
          <div className={classes.days}>
            <p>Poniedziałek</p>
            <p>Wtorek</p>
            <p>Środa</p>
            <p>Czwartek</p>
            <p>Piątek</p>
            <p>Sobota</p>
            <p>Niedziela</p>
          </div>
          <div className={classes.hours}>
            <p>{hoursData.Poniedzialek}</p>
            <p>{hoursData.Wtorek}</p>
            <p>{hoursData.Sroda}</p>
            <p>{hoursData.Czwartek}</p>
            <p>{hoursData.Piatek}</p>
            <p>{hoursData.Sobota}</p>
            <p>{hoursData.Niedziela}</p>
          </div>
        </div>
        <div className={classes.map}>
          <GoogleMap />
        </div>
      </div>
    </div>
  )
}

export default ContactData

const query = graphql`
  {
    strapiKontakt {
      email
      adres_dodatkowe_info
      miasto
      telefon
      ulica
    }
    strapiGodzinyOtwarcia {
      Czwartek
      Niedziela
      Piatek
      Poniedzialek
      Sobota
      Sroda
      Wtorek
    }
  }
`

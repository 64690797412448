import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import Contact from "../components/Contact/Contact"
import SEO from "../components/seo"

function Kontakt({ data }) {
  return (
    <Layout>
      <SEO title="Kontakt" description={data.strapiMetaTag.opis_strony} keywords={data.strapiMetaTag.tagi}/>
      <Contact title_photo={data.title_photo} />
    </Layout>
  )
}

export default Kontakt

export const query = graphql`
  query kontakt {
    strapiMetaTag(nazwa_strony: {eq: "kontakt"}) {
      opis_strony
      tagi
    }
    title_photo: file(relativePath: { eq: "kontakt.jpg" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

import React from "react"
import classes from "./GoogleMap.module.scss"

import { Map, GoogleApiWrapper, Marker } from "google-maps-react"

const mapStyles = {
  width: "100%",
  height: "100%",
  overflow: "hidden",
  position: "relative",
}

export class GoogleMap extends React.Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={18}
        className={classes.map}
        style={mapStyles}
        initialCenter={{ lat: 51.20688, lng: 16.158845 }}
      >
        <Marker position={{lat: 51.20688, lng: 16.158845}}/>
        </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAKIYb5XqGTB65TRj5V5IPXJIJKn1NlPC0",
})(GoogleMap)
